.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.clicked {
  -moz-box-shadow: inset 0 0 8px #000000;
  -webkit-box-shadow: inset 0 0 8px #000000;
  box-shadow: inset 0 0 8px #000000;
}

.single-bar {
  background: #f2f2f2;
  border-radius: 5px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.25);
  width: 100%;
}

@keyframes gradient {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: -200% 0;
  }
}

#bottom-line {
  margin-top: 3%;
  background-color: #00aeff;
  border-bottom: 1px solid #00aeff;
  width: 100%;
  height: 3px;
}

.bg-footer {
  background-color: #003463;
}

@media (min-width: 1200px) {
  #footer {
    width: 1170px;
  }
}

@media (min-width: 992px) {
  #footer {
    width: 970px;
  }
}

@media (min-width: 768px) {
  #footer {
    width: 969px;
  }
}

#footer {
  text-align: justify;
  padding: 15px;
  font-size: 14px;
  margin-right: auto;
  margin-left: auto;
}

table {
  width: 100%;
}

table,
th,
td {
  border: 1px solid white;
}

.button {
  border: none;
  text-align: center;
  text-shadow: 1px 1px 1px rgb(0 0 0 / 30%);
  color: white;
}

.fnt {
  font-size: 15px;
  font-weight: bold;
  line-height: 1.25rem;
}

.main-menu-outer-container>main {
  margin: 0;
  display: flex;
  overflow: auto;
}

.main-menu-outer-container>main>span:first-child {
  margin-left: auto;
}

.main-menu-outer-container>main>span {
  display: block;
  padding: 10px;
  min-width: 80px;
  font-size: 12px;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
}

.glyphicon {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: 'Glyphicons Halflings';
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
}

::-webkit-scrollbar {
  width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 3px;
  border-radius: 3px;
}

.jam {
  font-size: 13px;
  background: linear-gradient(to bottom, #33cc33 0%, #336600 100%)
}

.rtpValue {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.link-container {
  display: flex;
  justify-content: center;
  font-size: var(--x-large-font);
  padding: 0;
  width: 100%;
  align-self: center;
  margin-left: auto;
  margin-right: auto;
}

/* Pay4D */
.buttonsp4d {
  display: grid;
  width: 75%;
  margin-top: 2rem;
  margin-left: auto;
  margin-right: auto;
  grid-gap: 3px;
}

.px-btn {
  padding-left: 1rem;
  padding-right: 1rem;
}

.bgBtnRed {
  background-image: linear-gradient(#f17a77, #ee5f5b 60%, #ec4d49);
  padding: 14px 16px;
}

.bgBtnBlue {
  background-image: linear-gradient(#74cae3, #5bc0de 60%, #4ab9db);
  padding: 8px 12px;
}

.bgBtnYellow {
  background-image: linear-gradient(#faa123, #f89406 60%, #e48806);
  padding: 8px 12px;
}

.messagebleft-container {
  transition: all 500ms ease;
  transition-property: top, right, bottom, left, opacity;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  min-height: 14px;
  background-color: #070b0e;
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  line-height: 22px;
  padding: 18px 24px;
  bottom: -100px;
  top: -100px;
  opacity: 0;
  z-index: 9999
}

.messagebleft-container .action {
  background: inherit;
  display: inline-block;
  font-size: inherit;
  text-transform: uppercase;
  color: #4caf50;
  margin: 0 0 0 24px;
  padding: 0;
  min-width: min-content;
  cursor: pointer
}

@media(min-width:640px) {
  .messagebleft-container {
    min-width: 288px;
    max-width: 568px;
    display: inline-flex;
    border-radius: 2px;
    margin: 24px
  }
}

@media(max-width:640px) {
  .messagebleft-container {
    left: 0;
    right: 0;
    width: 100%
  }
}

.messagebleft-pos.bottom-center {
  top: auto !important;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0)
}

.messagebleft-pos.bottom-left {
  top: auto !important;
  bottom: 0;
  left: 0
}

.messagebleft-pos.bottom-right {
  top: auto !important;
  bottom: 0;
  right: 0
}

.messagebleft-pos.top-left {
  bottom: auto !important;
  top: 0;
  left: 0
}

.messagebleft-pos.top-center {
  bottom: auto !important;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0)
}

.messagebleft-pos.top-right {
  bottom: auto !important;
  top: 0;
  right: 0
}

@media(max-width:640px) {

  .messagebleft-pos.bottom-center,
  .messagebleft-pos.top-center {
    left: 0;
    transform: none
  }
}

.snowflake {
  color: #fff;
  font-size: 1em;
  font-family: Arial, sans-serif;
  text-shadow: 0 0 5px #000;
  position: fixed;
  top: -10%;
  z-index: 9999;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
  -webkit-animation-name: snowflakes-fall, snowflakes-shake;
  -webkit-animation-duration: 10s, 3s;
  -webkit-animation-timing-function: linear, ease-in-out;
  -webkit-animation-iteration-count: infinite, infinite;
  -webkit-animation-play-state: running, running;
  animation-name: snowflakes-fall, snowflakes-shake;
  animation-duration: 10s, 3s;
  animation-timing-function: linear, ease-in-out;
  animation-iteration-count: infinite, infinite;
  animation-play-state: running, running
}

@-webkit-keyframes snowflakes-fall {
  0% {
    top: -10%
  }

  100% {
    top: 100%
  }
}

@-webkit-keyframes snowflakes-shake {

  0%,
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0)
  }

  50% {
    -webkit-transform: translateX(80px);
    transform: translateX(80px)
  }
}

@keyframes snowflakes-fall {
  0% {
    top: -10%
  }

  100% {
    top: 100%
  }
}

@keyframes snowflakes-shake {

  0%,
  100% {
    transform: translateX(0)
  }

  50% {
    transform: translateX(80px)
  }
}

.snowflake:nth-of-type(0) {
  left: 1%;
  -webkit-animation-delay: 0s, 0s;
  animation-delay: 0s, 0s
}

.snowflake:first-of-type {
  left: 10%;
  -webkit-animation-delay: 1s, 1s;
  animation-delay: 1s, 1s
}

.snowflake:nth-of-type(2) {
  left: 20%;
  -webkit-animation-delay: 6s, .5s;
  animation-delay: 6s, .5s
}

.snowflake:nth-of-type(3) {
  left: 30%;
  -webkit-animation-delay: 4s, 2s;
  animation-delay: 4s, 2s
}

.snowflake:nth-of-type(4) {
  left: 40%;
  -webkit-animation-delay: 2s, 2s;
  animation-delay: 2s, 2s
}

.snowflake:nth-of-type(5) {
  left: 50%;
  -webkit-animation-delay: 8s, 3s;
  animation-delay: 8s, 3s
}

.snowflake:nth-of-type(6) {
  left: 60%;
  -webkit-animation-delay: 6s, 2s;
  animation-delay: 6s, 2s
}

.snowflake:nth-of-type(7) {
  left: 70%;
  -webkit-animation-delay: 2.5s, 1s;
  animation-delay: 2.5s, 1s
}

.snowflake:nth-of-type(8) {
  left: 80%;
  -webkit-animation-delay: 1s, 0s;
  animation-delay: 1s, 0s
}

.snowflake:nth-of-type(9) {
  left: 90%;
  -webkit-animation-delay: 3s, 1.5s;
  animation-delay: 3s, 1.5s
}

.snowflake:nth-of-type(10) {
  left: 25%;
  -webkit-animation-delay: 2s, 0s;
  animation-delay: 2s, 0s
}

.snowflake:nth-of-type(11) {
  left: 65%;
  -webkit-animation-delay: 4s, 2.5s;
  animation-delay: 4s, 2.5s
}