body {
  background-color: #1b1b1b;
  font-family: 'Open Sans', sans-serif;
  color: white;
}

.animated-gradient {
  background: repeating-linear-gradient(to right, #2f4d19 0%, #20380e 25%, #6efe01 50%, #20380e 75%, #2f4d19 100%);
  width: 100%;
  background-size: 200% auto;
  background-position: 0 100%;
  animation: gradient 2s infinite;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
}

.bg-item {
  background: linear-gradient(to bottom, #2f4d19 0%, #6efe01 46%, #2f4d19 100%);
  transition: 0s all;
}

.main-menu-outer-container {
  display: flex;
  background: linear-gradient(to bottom, #2f4d19 0%, #6efe01 46%, #2f4d19 100%);
}

.main-menu-outer-container>span {
  background: linear-gradient(to bottom, #2f4d19 0%, #6efe01 46%, #2f4d19 100%);
  color: rgb(211, 211, 211);
  display: flex;
  text-align: center;
  align-items: center;
  font-size: xx-large;
  top: 0;
}

.main-menu-outer-container>span:first-child {
  border-right: solid 2px #6efe01;
}

.main-menu-outer-container>span:last-child {
  border-left: solid 2px #6efe01;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 3px;
  border-radius: 3px;
  background: linear-gradient(to bottom, #2f4d19 0%, #6efe01 46%, #2f4d19 100%);
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.5);
}

.single-bar__fill {
  background-color: #6efe01;
  border-radius: 3px;
  height: 20px;
}

/* Soon For Footer */
.copyright-bg {
  background: linear-gradient(to bottom, #2e2e2e, #313131, #292929, #242424, #1d1d1d, #1d1d1d, #1d1d1d, #1d1d1d, #1d1d1d);
  color: #fff;
  border-top: 3px #00aeff solid;
}